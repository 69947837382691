import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { Header, Cotizacion, DetalleCotizacion } from '../components';
import { Tabs, Tab } from '@mui/material';
import axios from 'axios';
import moment from 'moment'; // Para manejar fechas

const TabWithIcon = ({ color, label }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span
      style={{
        backgroundColor: color,
        borderRadius: '50%',
        width: '8px',
        height: '8px',
        marginRight: '8px',
      }}
    />
    {label}
  </div>
);

const Cotizaciones = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [cotizacionVisible, setCotizacionVisible] = useState(false);
  const [detallecotizacionVisible, setDetalleCotizacionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCotizacionData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const token = localStorage.getItem('access_token');
      if (!token) {
        setError('No se ha encontrado el token de autenticación. Por favor, inicia sesión.');
        return;
      }

      let response;
      switch (selectedTab) {
        case 0: // Pendiente
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=pendiente`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 1: // Aprobado
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=aprobado`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 2: // Cancelado
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/?estado=cancelado`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
        case 3: // Todas las cotizaciones
        default:
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cotizaciones/`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          break;
      }

      if (response && response.data) {
        setFilteredData(response.data);
      } else {
        setError('No se recibieron datos del servidor.');
      }
    } catch (error) {
      setError('Error al cargar las cotizaciones.');
      console.error('Error fetching cotizacion data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCotizacionData();
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNewCotizacionClick = () => {
    setCotizacionVisible(true);
  };

  const handleRowDoubleClick = () => {
    setDetalleCotizacionVisible(true);
  };

  const isOverdue = (date, status) => {
    const twoWeeksAgo = moment().subtract(14, 'days');
    const recordDate = moment(date, 'YYYY-MM-DD');
    return status === 'pendiente' && recordDate.isBefore(twoWeeksAgo);
  };

  const handleRowDataBound = (args) => {
    if (isOverdue(args.data.fecha_cotizacion, args.data.estado)) {
      args.row.style.backgroundColor = '#FFE5E5'; // Resalta la fila en rojo claro
      args.row.style.color = '#FF0000'; // Cambia el color del texto a rojo
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Cotizaciones" />
      
      <div className="flex justify-between items-center">
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label={<TabWithIcon color="#FEC90F" label="Pendiente" />} />
          <Tab label={<TabWithIcon color="#8BE78B" label="Aprobado" />} />
          <Tab label={<TabWithIcon color="red" label="Cancelado" />} />
          <Tab label="Todas las cotizaciones" />
        </Tabs>
        
        <button
          type="button"
          onClick={handleNewCotizacionClick}
          style={{
            backgroundColor: '#1fb519',
            color: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontWeight: 'lighter',
            textTransform: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          + Nueva Cotización
        </button>
      </div>

      {cotizacionVisible && (
        <Cotizacion
          onClose={() => setCotizacionVisible(false)}
        />
      )}

      {detallecotizacionVisible && (
        <DetalleCotizacion
          onClose={() => setDetalleCotizacionVisible(false)}
        />
      )}

      {isLoading ? (
        <p>Cargando datos...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
<GridComponent
  dataSource={filteredData}
  enableHover={false}
  allowPaging
  pageSettings={{ pageCount: 5 }}
  selectionSettings={{ persistSelection: true }}
  toolbar={[]}
  editSettings={{ allowDeleting: true, allowEditing: true }}
  allowSorting
  className="mt-4"
  recordDoubleClick={handleRowDoubleClick}
  rowDataBound={handleRowDataBound}
>
  <ColumnsDirective>
    {/* ID Cotización */}
    <ColumnDirective field="id_cotizacion" headerText="ID Cotización" width="150" />
    
    {/* Solicitante */}
    <ColumnDirective field="solicitante.nombre_completo" headerText="Solicitante" width="200" />

    {/* Fecha Cotización */}
    <ColumnDirective field="fecha_cotizacion" headerText="Fecha" width="150" format="yMd" />

    {/* Estado de Cotización */}
    <ColumnDirective field="estado" headerText="Estado" width="100" />

    {/* Total Cotización */}
    <ColumnDirective field="total_cotizacion" headerText="Valor" width="150" format="C2" />

    {/* Ubicación */}
    <ColumnDirective field="ubicacion" headerText="Ubicación" width="200" />

    {/* Dirección de Instalación */}
    <ColumnDirective field="direccion_instalacion" headerText="Dirección" width="250" />
  </ColumnsDirective>
  <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
</GridComponent>

      )}
    </div>
  );
};

export default Cotizaciones;
