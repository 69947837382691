const CotizacionConfirmacion = ({
  onClose,
  cotizacionNumero,
  fechaCotizacion,
  cliente,
  direccion,
  telefono,
  items,
  totalM2,
  totalCotizacion,
  descuento,
  tipoInstalacion,
  retiroLaminas,
  metrosRetirar,
  montoRetiro,
  cobroDistancia,
  montoDistancia,
  tipoDificultad,   // Añadido
  montoDificultad,  // Añadido
  notasAdicionales
}) => {
  return (
    <div className="bg-half-transparent w-screen h-screen fixed nav-item top-0 left-0 flex items-center justify-center">
      <div className="bg-white dark:bg-[#484B52] p-8 rounded-lg w-full max-w-lg mx-auto" style={{ height: '90vh', overflowY: 'auto' }}>
        <div className="mb-4">
          <p className="font-semibold text-lg" style={{ color: '#FFa800' }}>
            Cotización <span>{cotizacionNumero}</span>
          </p>
          <p className="text-sm text-gray-500">{tipoInstalacion.toUpperCase()}</p>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Fecha de cotización:</p>
            <p className="text-sm">{fechaCotizacion}</p>
          </div>
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-500">Cotización para:</p>
          <p className="text-sm">{cliente}</p>
          <p className="text-sm text-gray-500">{direccion}</p>
          <p className="text-sm">{telefono}</p>
        </div>

        <div className="mb-4">
          <p className="font-semibold text-gray-700">Items Cotización</p>
          <div className="border rounded-md p-4">
            <table className="w-full text-sm">
              <thead>
                <tr>
                  <th className="text-left text-gray-500">Lámina</th>
                  <th className="text-left text-gray-500">Alto</th>
                  <th className="text-left text-gray-500">Ancho</th>
                  <th className="text-left text-gray-500">M²</th>
                  <th className="text-left text-gray-500">Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.lamina}</td>
                    <td>{item.alto} m</td>
                    <td>{item.ancho} m</td>
                    <td>{item.m2}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Sección de montos adicionales */}
            <div className="flex justify-between mt-4">
              <p className="text-sm">Total m²:</p>
              <p className="text-sm">{totalM2} m²</p>
            </div>
            {/* Monto por retiro de láminas */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto retiro de láminas:</p>
              <p className="text-sm">${(montoRetiro || 0).toLocaleString()}</p>
            </div>

            {/* Monto por distancia */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto cobro por distancia:</p>
              <p className="text-sm">${(montoDistancia || 0).toLocaleString()}</p>
            </div>

            {/* Monto por dificultad */}
            <div className="flex justify-between mt-2">
              <p className="text-sm">Monto por dificultad:</p>
              <p className="text-sm">${(montoDificultad || 0).toLocaleString()}</p>
            </div>
            {descuento > 0 && (
              <div className="flex justify-between mt-2">
                <p className="text-sm">Descuento:</p>
                <p className="text-sm">- {descuento}%</p>
              </div>
            )}
                        <div className="flex justify-between mt-2">
                          <p className="text-sm">Total cotización:</p>
                          <p className="text-sm">{totalCotizacion}</p>
                        </div>
                      </div>
                    </div>

        {/* Información resumida del tipo de instalación, retiro de láminas, distancia y dificultad */}
        <div className="mb-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Tipo de instalación:</p>
            <p className="text-sm">{tipoInstalacion}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Retiro de láminas:</p>
            <p className="text-sm">
              {retiroLaminas === 'Sí'
                ? `${retiroLaminas} ${metrosRetirar || 0} m²`
                : 'No'}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Cobro por distancia:</p>
            <p className="text-sm">{cobroDistancia ? 'Sí' : 'No'}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Dificultad:</p>
            <p className="text-sm">
              {tipoDificultad !== 'Normal' ? `${tipoDificultad}` : 'Normal'}
            </p>
          </div>

          
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-500">Notas adicionales:</p>
          <p className="text-sm">{notasAdicionales || 'No se ingresaron notas adicionales.'}</p>
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={onClose}
            className="bg-gray-300 p-2 rounded text-gray-700"
          >
            Cerrar
          </button>
          <button
            onClick={onClose}
            className="p-2 rounded text-white"
            style={{ backgroundColor: '#FFa800' }}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CotizacionConfirmacion;
