import React from 'react';
import { IoIosMore } from 'react-icons/io';
import { Pie, LineChart, Stacked } from '../components';
import { recentTransactions, recentCotizaciones, ecomPieChartData } from '../data/dummy';
import { lineCustomSeries, LinePrimaryXAxis, LinePrimaryYAxis, gananciasMensuales, stackedPrimaryXAxis, stackedPrimaryYAxis, IngresosEgresos } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { Header } from '../components';

const Dashboard = () => {
  const { currentColor, currentMode } = useStateContext();

  return (
    <div className="m-2 md:m-4 mt-24 p-2 md:p-4 bg-white rounded-3xl">
      <Header title="Dashboard" />

      {/* Fila 1: Ganancias y Orígenes Cotizaciones */}
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-full lg:w-1/2 md:w-full">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">Ganancias</p>
          </div>
          <div className="w-full overflow-auto">
            <Stacked 
              id="stacked-chart-1" 
              width="100%" 
              height="300px" 
              data={gananciasMensuales} 
              xAxis={stackedPrimaryXAxis} 
              yAxis={stackedPrimaryYAxis} 
            />
          </div>
        </div>

        <div className="w-full lg:w-1/2 md:w-full bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Orígenes Cotizaciones</p>
            <button type="button" className="text-xl font-semibold text-gray-400">
              <IoIosMore />
            </button>
          </div>
          <div className="w-full">
            <Pie id="pie-chart" data={ecomPieChartData} legendVisiblity={false} height="300px" />
          </div>
        </div>
      </div>

      {/* Fila 2: Balance y Stock Láminas */}
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-full lg:w-1/2 md:w-full">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">Balance: Ingresos vs Gastos</p>
          </div>
          <div className="w-full overflow-auto">
            <Stacked 
              id="stacked-chart-2" 
              width="100%" 
              height="300px" 
              data={IngresosEgresos} 
              xAxis={stackedPrimaryXAxis} 
              yAxis={stackedPrimaryYAxis} 
            />
          </div>
        </div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-full lg:w-1/2 md:w-full">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">Stock Láminas</p>
          </div>
          <div className="w-full overflow-auto">
            <LineChart 
              id="line-chart-2" 
              data={lineCustomSeries} 
              xAxis={LinePrimaryXAxis} 
              yAxis={LinePrimaryYAxis} 
              width="100%" 
              height="300px" 
            />
          </div>
        </div>
      </div>

      {/* Fila 3: Últimas Cotizaciones y Últimas Transferencias */}
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="w-full lg:w-1/2 md:w-full bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Últimas Cotizaciones</p>
          </div>
          <div className="mt-10 w-full md:w-400">
            {recentCotizaciones.map((item) => (
              <div key={item.title} className="flex justify-between mt-4">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{
                      color: item.iconColor,
                      backgroundColor: '#FFa800',
                    }}
                    className="text-2xl rounded-lg p-1 hover:drop-shadow-xl"
                  >
                    <img
                      src={item.icon}
                      alt={item.title}
                      className="h-10 w-10 object-contain rounded-lg"
                    />
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>
                <p className={`text-${item.pcColor}`}>{item.estado}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full lg:w-1/2 md:w-full bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Últimos Pagos</p>
          </div>
          <div className="mt-10 w-full md:w-400">
            {recentTransactions.map((item) => (
              <div key={item.title} className="flex justify-between mt-4">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconBg,
                    }}
                    className="text-2xl rounded-lg p-4 hover:drop-shadow-xl"
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>
                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Media Query Styles */}
      <style jsx>{`
        @media (max-width: 1360px) {
          #stacked-chart-1,
          #stacked-chart-2,
          #pie-chart,
          #line-chart-2 {
            height: 250px !important;
          }
        }

        @media (min-width: 1361px) {
          #stacked-chart-1,
          #stacked-chart-2,
          #pie-chart,
          #line-chart-2 {
            height: 400px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
